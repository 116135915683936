import React from "react";
import { graphql } from "gatsby";
import { Button, Container, Col, Row } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";

// markup
const NotFoundPage = ({ data }) => {
  if (!data) return null;

  const {
    fof_title: title,
    fof_description: description,
    fof_image: image,
    fof_image_mobile: imageMobile,
    fof_link: link,
    fof_link_label: linkLabel,
  } = data.settings.data;

  return (
    <Layout settings={data.settings}>
      <Container className="py-6 text-center">
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            {image.gatsbyImageData && (
              <GatsbyImage
                image={image.gatsbyImageData}
                alt={image.alt}
                className={
                  imageMobile?.gatsbyImageData ? "d-none d-md-block" : null
                }
              />
            )}
            {imageMobile.gatsbyImageData && (
              <GatsbyImage
                image={imageMobile.gatsbyImageData}
                alt={imageMobile.alt}
                className="d-md-none"
              />
            )}
          </Col>
          <Col sm={12}>
            <h1>{title}</h1>
            <p>{description}</p>
            <Button as={Anchor} href={link.url}>
              {linkLabel}
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query notFoundQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
  }
`;

export default NotFoundPage;
